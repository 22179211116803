import React, {Suspense} from "react";
import SplashScreen from "./SplashScreen";
import Loader from "../common/loading-widgets/Loader";
import useShowLoader from "../common/loading-widgets/useShowLoader";

// Only selected app will be loaded, when it is needed
const AppRecruiters = React.lazy(() => import('./AppRecruiters'));
const AppB2B = React.lazy(() => import('./AppB2B'));

// Until the lazy imports are finished, React Suspense displays the fallback component. We want the fallback to be
// a splash screen that lasts longer, so as a fallback component we use this component to trigger the loading state of a
// Loader placed higher in the hierarchy
const LongerSuspense = () => {
  // Splash screen starts in loading state
  const [showLoader, setShowLoader] = React.useState(true);
  useShowLoader(showLoader, "LongerSuspense");

  // When the Suspense destroys the fallback, we tell the splash screen we're not in loading state anymore.
  // The splash screen will honour the minimal display delay.
  React.useEffect(() => {
    return () => setShowLoader(false);
  }, []);

  return null;
}

// Main App
export default function App() {

  let AppComponent;

  // Select app to load depending on environment variable
  switch (process.env.REACT_APP_BUNDLE) {
    case "recruiters":
      AppComponent = AppRecruiters;
      break;
    case "b2b":
      AppComponent = AppB2B;
      break;
    default:
      AppComponent = null;
  }

  return (
    <Loader icon={<SplashScreen/>} minDelay={3000} name={"main"}>
      <Suspense fallback={<LongerSuspense/>}>
        <AppComponent/>
      </Suspense>
    </Loader>
  );
}
